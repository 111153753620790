import React from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import BlogPostCard2 from '../components/blog-post-card2'
import Question1 from '../components/question1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Удвойте Ваш Доход: Секреты Успешных Инвестиций</title>
        <meta
          name="description"
          content="Раскройте потенциал удвоения дохода. Узнайте стратегии, которые могут привести к значительному росту вашего капитала и финансовой независимости."
        />
        <meta
          property="og:title"
          content="Удвойте Ваш Доход: Секреты Успешных Инвестиций"
        />
        <meta
          property="og:description"
          content="Раскройте потенциал удвоения дохода. Узнайте стратегии, которые могут привести к значительному росту вашего капитала и финансовой независимости."
        />
      </Helmet>
      <div className="home-hero heroContainer">
        <div className="home-container01">
          <h1 className="home-hero-heading">
            Удвойте Ваш Доход: СРАВНЕНИЕ ВАРИАНТОВ ИНВЕСТИЦИЙ
          </h1>
          <span className="home-hero-sub-heading bodyLarge">
            <span>
              <span>
                <span>Your Gateway to the Future of Finance</span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <div className="home-btn-group"></div>
        </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <h2 className="home-features-heading">
                Разнообразие Инвестиционных Инструментов
              </h2>
              <span className="home-features-sub-heading bodyLarge">
                <span>
                  <span>
                    <span>
                      Discover the key features that make our cryptocurrency
                      platform stand out
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                  <span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div className="home-container03">
              <FeatureCard
                heading="Акции"
                subHeading="Владение частью компании и возможность заработать на ее успехе."
              ></FeatureCard>
              <FeatureCard
                heading="Облигации"
                subHeading="Заимствование денег государству или компании с обещанием возврата с процентами."
              ></FeatureCard>
              <FeatureCard
                heading="Недвижимость"
                subHeading="Инвестиции в землю и здания с целью получения дохода от аренды или продажи."
              ></FeatureCard>
              <FeatureCard
                heading="Криптовалюты"
                subHeading="Цифровые деньги, предлагающие быстрый рост, но с высоким риском."
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-comparision">
        <div className="home-container04">
          <BlogPostCard2
            profileSrc="https://images.unsplash.com/photo-1611232658409-0d98127f237f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName3"
          ></BlogPostCard2>
        </div>
        <div className="home-container05">
          <BlogPostCard2
            imageSrc="https://images.unsplash.com/photo-1595565312451-23051ab0666c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1000"
            profileSrc="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName"
          ></BlogPostCard2>
        </div>
        <div className="home-container06">
          <BlogPostCard2
            imageSrc="https://images.unsplash.com/photo-1595565312451-23051ab0666c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1000"
            profileSrc="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName4"
          ></BlogPostCard2>
        </div>
        <div className="home-container07">
          <BlogPostCard2
            imageSrc="https://images.unsplash.com/photo-1595565312451-23051ab0666c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1000"
            profileSrc="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName1"
          ></BlogPostCard2>
        </div>
      </div>
      <div className="home-faq">
        <div className="faqContainer">
          <div className="home-faq1">
            <div className="home-container08">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text31">
                <span className="home-text32 heading2">
                  Часто Задаваемые Вопросы
                </span>
                <br></br>
              </h2>
            </div>
            <div className="home-container09">
              <Question1
                answer="Облигации - это форма займа, который инвестор предоставляет эмитенту (государству или компании), в отличие от акций, которые представляют собственность."
                question="Как облигации отличаются от акций?"
              ></Question1>
              <Question1
                answer="Криптовалюты могут быть очень волатильными, и их стоимость может сильно колебаться, что увеличивает риск потерь."
                question="Какие риски связаны с инвестированием в криптовалюты?"
              ></Question1>
              <Question1
                answer="Многие онлайн-платформы позволяют начать инвестировать с небольшими суммами и предлагают автоматические инвестиционные планы."
                question="Как начать инвестировать с ограниченным бюджетом? "
              ></Question1>
              <Question1
                answer="Blockchain is the underlying technology behind cryptocurrencies. It is a decentralized and distributed ledger that records transactions across multiple computers in a secure and transparent manner."
                question="What is blockchain technology?"
              ></Question1>
              <Question1
                answer="While it is possible to mine certain cryptocurrencies at home using your computer's processing power, it may not be profitable due to the high energy costs and competition from specialized mining hardware."
                question="Can I mine cryptocurrency at home?"
              ></Question1>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer"></div>
      <div className="home-hero1"></div>
    </div>
  )
}

export default Home
