import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card2.css'

const BlogPostCard2 = (props) => {
  return (
    <div className={`blog-post-card2-blog-post-card ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        image_src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
        className="blog-post-card2-image"
      />
      <div className="blog-post-card2-container">
        <h1 className="blog-post-card2-text">{props.title}</h1>
        <div className="blog-post-card2-container1">
          <h2 className="blog-post-card2-text1">{props.title1}</h2>
          <span className="blog-post-card2-text2">{props.description}</span>
        </div>
        <div className="blog-post-card2-container2">
          <h2 className="blog-post-card2-text3">{props.title12}</h2>
          <span className="blog-post-card2-text4">{props.description2}</span>
        </div>
      </div>
    </div>
  )
}

BlogPostCard2.defaultProps = {
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
  title1: 'Lorem ipsum dolor sit amet',
  title12: 'Lorem ipsum dolor sit amet',
  title11: 'Lorem ipsum dolor sit amet',
  description2:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
  rootClassName: '',
  profileSrc:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&h=1200',
  label: 'ENTERPRISE',
  imageSrc:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&w=1500',
  when: '3 days ago',
  description1:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
  title: 'Lorem ipsum dolor sit amet',
  author: 'Jane Doe',
  imageAlt: 'image',
  profileAlt: 'profile',
}

BlogPostCard2.propTypes = {
  description: PropTypes.string,
  title1: PropTypes.string,
  title12: PropTypes.string,
  title11: PropTypes.string,
  description2: PropTypes.string,
  rootClassName: PropTypes.string,
  profileSrc: PropTypes.string,
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  when: PropTypes.string,
  description1: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  imageAlt: PropTypes.string,
  profileAlt: PropTypes.string,
}

export default BlogPostCard2
